import {
  Elements,
  useStripe,
  CardElement,
  useElements,
} from '@stripe/react-stripe-js'
import React, { FC, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import useLocalStorageState from 'use-local-storage-state'

import StripeForm from './StripeForm'
import { getStore } from 'common/store'
import { Project } from 'common/project'
import Button from './base/forms/Button'
import { ErrorMessage } from './Messages'
import ProductSelect from './ProductSelect'
import PaymentTypeCard from './PaymentTypeCard'
import { getWebEntitlements } from 'common/services/useWebEntitlement'
import { createSubscriptionToken } from 'common/services/useSubscriptionToken'
import AccoutCreationStepper, {
  AccoutCreationStep,
} from './AccountCreationStepper'
import CollapsibleMenu from './CollapsibleMenu'
import { useRouter } from 'next/router'
import { createSubscriptionConfirm } from 'common/services/useSubscriptionConfirm'

type SetupSubscriptionType = {}

const stripePromise = loadStripe(Project.stripe)

type StripeInnerType = {}

const StripeInner: FC<StripeInnerType> = ({}) => {
  const stripe = useStripe()
  const elements = useElements()
  const router = useRouter()
  const [product, setProduct] = useLocalStorageState('product', {
    defaultValue: '',
  })

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [cardValid, setCardValid] = useState(false)
  const [cardError, setCardError] = useState<{
    cardHolder: string | null
    cardNumber: string | null
  }>({
    cardHolder: null,
    cardNumber: null,
  })

  const handleSubmit = async (e) => {
    e?.preventDefault()
    if (isLoading) return
    setIsLoading(true)
    createSubscriptionToken(getStore(), {
      price_id: product,
    })
      .then((subscriptionToken) => {
        if (subscriptionToken.error) {
          setError(subscriptionToken.error)
        } else {
          return stripe!
            .confirmCardPayment(subscriptionToken.data.client_secret, {
              payment_method: {
                card: elements!.getElement(CardElement)!,
              },
            })
            .then(async (res) => {
              if (res.error?.message) {
                setError(res.error.message)
                return
              } else {
                await createSubscriptionConfirm(getStore(), {
                  stripe_subscription_id:
                    subscriptionToken.data.subscription_id,
                }).then(() => {
                  if (res.error) {
                    setError(res.error)
                  } else {
                    getWebEntitlements(getStore(), {}, { forceRefetch: true })
                  }
                })
                router.push('/download')
                return
              }
            })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const [selectedPaymentType, setselectedPaymentType] = useState('card')

  return (
    <form onSubmit={handleSubmit}>
      <span className='text-small text-bold d-inline-flex mb-2'>
        Confirm Plan
      </span>
      <ProductSelect value={product} onChange={setProduct} />
      <span className='text-small text-bold d-inline-flex mt-3'>
        Payment Type
      </span>
      <div className='row mt-2'>
        {/* <PaymentTypeCard
          isSelected={selectedPaymentType === 'card'}
          type='card'
          onClick={() => {
            setselectedPaymentType('card')
          }}
        />
        <PaymentTypeCard
          isSelected={selectedPaymentType === 'paypal'}
          type='paypal'
          onClick={() => {
            setselectedPaymentType('paypal')
          }}
        /> */}
      </div>
      <StripeForm
        onChange={(e) => {
          setCardValid(e.complete && !e.error)
          setCardError({
            ...cardError,
            cardNumber: e.error ? e.error.message : null,
          })
        }}
      />

      <ErrorMessage>{error}</ErrorMessage>
      <Button
        aria-label='submit-payment-btn'
        className='full-width mt-4-half'
        type='submit'
        disabled={isLoading || !cardValid}
      >
        {isLoading ? 'Subscribing...' : 'Subscribe'}
      </Button>
    </form>
  )
}

const SetupSubscription: FC<SetupSubscriptionType> = ({}) => {
  const [openMenu, setOpenMenu] = useState<AccoutCreationStep>('payment')

  return (
    <div className='row g-0'>
      <div className='col-1 me-4 me-md-0'>
        <AccoutCreationStepper
          disabledMenu='payment'
          stepActive={openMenu}
          registrationComplete
        />
      </div>
      <div className='col-10 col-lg-4 col-md-6'>
        <h3 className='mt-8 mb-5-half'>Payment</h3>
        <CollapsibleMenu
          disabled
          title='Choose your plan'
          menuId='plan'
          isOpen={false}
        />
        <CollapsibleMenu
          disabled
          title='Your Details'
          menuId='user'
          isOpen={false}
        />
        <CollapsibleMenu
          isOpen={openMenu === 'payment'}
          onClick={() => setOpenMenu('payment')}
          title='Payment Details'
          menuId='payment'
        >
          <Elements stripe={stripePromise} options={{}}>
            <StripeInner />
          </Elements>
        </CollapsibleMenu>
      </div>
    </div>
  )
}

export default SetupSubscription
